import React, { useEffect, useState,useRef} from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Toolbar,
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  TextField,
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Servicios = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyle = {
      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '20vh',
    };

    const containerStylee = {
        background: `url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
      };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <>
            <NavbarL/>
            <FloatingWhatsAppButton/>
            <br />
                <br />
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                        Servicios
                    </Typography>
                <br />
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="center" alignItems="center" spacing={2} >
                            <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                                <Paper
                                    sx={{
                                        borderRadius: 5,
                                        bgcolor: '#e8eaf6',
                                        width: '400px',
                                        height: isMobile ? '100%' : '200px',
                                        p: 3,
                                    }}
                                >
                                    <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                        Proyectos IoT (Internet of Things)
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Impulsamos la innovación con soluciones de Internet de las Cosas (IoT) personalizadas, diseñadas para optimizar 
                                        procesos y mejorar la conectividad de tus dispositivos. Desde la integración de sensores hasta la automatización 
                                        completa, nuestros proyectos están orientados a transformar tu negocio a través de la tecnología avanzada.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                                <Paper
                                    sx={{
                                        borderRadius: 5,
                                        bgcolor: '#e8eaf6',
                                        width: '400px',
                                        height: isMobile ? '100%' : '200px',
                                        p: 3,
                                    }}
                                >
                                    <Typography variant="h5" align="left" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                        Desarrollo dispositivos electronicos
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Ofrecemos servicios especializados en el desarrollo de dispositivos electrónicos, incluyendo sistemas de comunicación, 
                                        sensorización y control. Nuestro enfoque garantiza dispositivos eficientes y adaptados a las necesidades específicas 
                                        de cada cliente, destacando por su precisión técnica y fiabilidad.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                                <Paper
                                    sx={{
                                        borderRadius: 5,
                                        bgcolor: '#e8eaf6',
                                        width: '400px',
                                        height: isMobile ? '100%' : '200px',
                                        p: 3,
                                    }}
                                >
                                    <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                        Soporte web
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Proporcionamos soporte web integral para asegurar el óptimo funcionamiento de tus plataformas online. Desde la solución de problemas técnicos 
                                        hasta actualizaciones periódicas, nuestro equipo está preparado para ayudarte a mantener tu sitio web o sistema en línea sin interrupciones.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                                <Paper
                                    sx={{
                                        borderRadius: 5,
                                        bgcolor: '#e8eaf6',
                                        width: '400px',
                                        height: isMobile ? '100%' : '200px',
                                        p: 3,
                                    }}
                                >
                                    <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                        Aplicacion web
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Diseñamos y desarrollamos aplicaciones web a medida que se adaptan perfectamente a tus requisitos de negocio. Nuestras aplicaciones son seguras, 
                                        escalables y centradas en proporcionar una experiencia de usuario excepcional, ayudando a mejorar la interacción y satisfacción de los clientes.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                                <Paper
                                    sx={{
                                        borderRadius: 5,
                                        bgcolor: '#e8eaf6',
                                        width: '400px',
                                        height: isMobile ? '100%' : '200px',
                                        p: 3,
                                    }}
                                >
                                    <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                        Servidores
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Ofrecemos soluciones de servidor personalizadas que se adaptan a las necesidades específicas de tu empresa. Ya sea para hosting de 
                                        aplicaciones, datos o servicios de copia de seguridad, nuestros servidores están configurados para proporcionar un rendimiento 
                                        óptimo y seguridad de nivel superior. 
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <br />
            <TerminoPag/>
        </>
    )
}

export default Servicios
