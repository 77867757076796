import React, { useEffect, useState,useRef} from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Toolbar,
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  TextField,
} from "@mui/material";
import fondo from "./../Imagenes/Original-SUGAL-GROUP-01.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Proyectos = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const settings = {
        dots: true,
        infinite: true,
        speed: 250,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const getInfoByItem = (item) => {
        switch (item) {
            case 1:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 2:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 3:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 4:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 5:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 6:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            default:
                return {};
        }
    };

    return(
        <>
            <NavbarL/>
            <FloatingWhatsAppButton/>
            <br />
                <br />
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    
                    <Typography variant="h4" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    Nuestros Clientes
                </Typography>
                <br />
                <br />
                <Slider {...settings}>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                        <Grid>
                            <Grid key={item} item justifyContent="center" alignItems="center" style={{ padding: "10px", margin: "auto", textAlign: "center" }}>
                                <CardActionArea>
                                    <CardMedia component="img" sx={{ maxWidth: "150px", height: "50px", margin: "auto" }} image={getInfoByItem(item).image} alt="Vehiculos" />
                                    <Typography variant="h6" component="div" textAlign="center">
                                        {getInfoByItem(item).title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="center">
                                        {getInfoByItem(item).passengers}
                                    </Typography>
                                    <CardContent>
                                    </CardContent>
                                </CardActionArea>
                            </Grid>
                        </Grid>
                    ))}
                </Slider>
                <br />
                <br />
                <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    Proyectos Realizados
                </Typography>

                <Typography variant="h5" align="left" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    Dispositivo de Diseño de Placa Electrónica Multipropósito
                </Typography>
                <Typography variant="h6" align="left" style={{ letterSpacing: "-0.07em", color: "#000000" }} gutterBottom>
                    Desarrollamos un dispositivo avanzado para el diseño de placas electrónicas multipropósito, optimizando su funcionalidad y versatilidad para diversas aplicaciones.
                </Typography>

                <Typography variant="h5" align="left" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    Desarrollos Realizados para Sugal
                </Typography>
                <Typography variant="h6" align="left" style={{ letterSpacing: "-0.07em", color: "#000000" }} gutterBottom>
                    <ul>
                        <li><strong>Contador de Plantas con Sensor IR:</strong> Implementamos un sistema de conteo de plantas utilizando tecnología de sensores infrarrojos, mejorando la precisión y eficiencia en el monitoreo de cultivos.</li>
                        <li><strong>Sistema de Pareamiento para Traqueo Operacional:</strong> Desarrollamos un sistema innovador para el seguimiento y pareamiento de operaciones, optimizando la gestión y control de procesos en tiempo real.</li>
                        <li><strong>Controlador de Riego Automatizado:</strong> Creamos un controlador avanzado para la automatización del riego, permitiendo una gestión eficiente y precisa del recurso hídrico.</li>
                        <li><strong>Accionamiento de Válvulas a Distancia:</strong> Diseñamos un sistema de accionamiento remoto para válvulas, facilitando el control y operación desde cualquier lugar con acceso a internet.</li>
                        <li><strong>Dosificador Automático de Fertilizantes:</strong> Implementamos un sistema automatizado para la dosificación de fertilizantes, asegurando la cantidad exacta necesaria para el óptimo crecimiento de los cultivos.</li>
                        <li><strong>Programación de Riego por Calendario:</strong> Desarrollamos una herramienta para la programación de riego basada en calendarios, permitiendo una planificación precisa y personalizada según las necesidades de cada cultivo.</li>
                        <li><strong>Sistema Web Predictivo de Riego:</strong> Creamos un sistema web avanzado que predice las necesidades de riego basándose en datos históricos y condiciones actuales, optimizando el uso del agua y mejorando la productividad agrícola.</li>
                    </ul>
                </Typography>

                <br />
                <br />
                </Container>
                <br />
                <br />
                <br />
            <TerminoPag/>
        </>
    )
}

export default Proyectos
