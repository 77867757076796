import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Link,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Image from 'mui-image';
import logo from "./../Imagenes/pmcustom_preview_rev_2.png";
import playstore from "./../Imagenes/Google_Play_Store.svg.png";
import appstore from "./../Imagenes/App_Store.svg.png";
import { Call, Mail, WhatsApp } from '@mui/icons-material';
import ContactsIcon from '@mui/icons-material/Contacts';

const TerminoPag = () => {
  const theme = useTheme();
  const handleWhatsappClick = () => {
    window.open('https://api.whatsapp.com/send?phone=56921685636&text=', '_blank');
  };

  return (
    <>
      <Box sx={{ bgcolor: "#e0e0e0" }}>
        <Container maxWidth="xl">
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={3}>
              <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                <Image width={200} height={150} src={logo} duration={500} />
                <br/>
                <IconButton
                  sx={{
                    bgcolor: "#8900AC",
                    color: "white",
                    borderRadius: "100%", 
                    border: "1px solid #8900AC",
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                      bgcolor: "#7e57c2",
                    },
                  }}
                  onClick={handleWhatsappClick}
                >
                  <WhatsApp />
                </IconButton>
                <br/>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9} container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h6" fontWeight="bold" color="white" sx={{ textShadow: '2px 2px 2px black' }}>
                  Nosotros
                </Typography>
                <br/>
                <Link component={RouterLink} to="/Quienes-Somos" sx={{ textDecoration: 'none' }}>
                  <Typography variant="body2" color="black" sx={{ display: 'flex', alignItems: 'center', fontSize: '17px', fontWeight: 'bold' }}>
                    <ContactsIcon sx={{ bgcolor: "#8900AC", color: "white", borderRadius: "100%", border: "3px solid #8900AC", marginRight: '5px' }} />
                    Quiénes somos
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} md={6} container direction="column" alignItems="center" alignItems="center"  spacing={2}>
                <Typography variant="h6" fontWeight="bold" color="white" sx={{ textShadow: '2px 2px 2px black' }}>
                  Contacto
                </Typography>
                <br/>
                <Link href="tel:+56921685636" sx={{ textDecoration: 'none' }}>
                  <Typography variant="body2" color="black" sx={{ display: 'flex', alignItems: 'center', fontSize: '17px', fontWeight: 'bold' }}>
                    <Call sx={{ bgcolor: "#8900AC", color: "white", borderRadius: "100%", border: "3px solid #8900AC", marginRight: '5px' }} />
                    +56 9 2168 5636
                  </Typography>
                </Link>
                <br/>
                <Link href="mailto:Adquisiciones@pmcustom.cl" sx={{ textDecoration: 'none' }}>
                  <Typography variant="body2" color="black" sx={{ display: 'flex', alignItems: 'center', fontSize: '17px', fontWeight: 'bold' }}>
                    <Mail sx={{ bgcolor: "#8900AC", color: "white", borderRadius: "100%", border: "3px solid #8900AC", marginRight: '5px' }} />
                    Adquisiciones@pmcustom.cl
                  </Typography>
                </Link>
              </Grid>
              {/* <Grid item xs={12} md={4} container direction="column" alignItems="center"  spacing={2}>
                <Typography variant="h6" fontWeight="bold" color="white" sx={{ textShadow: '2px 2px 2px black' }}>
                  Próximamente
                </Typography>
                <br/>
                <img src={playstore} alt="playstore" style={{ width: '200px', height: 'auto', marginTop: '5px', marginBottom: '5px' }} />
                <img src={appstore} alt="appstore" style={{ width: '200px', height: 'auto', marginTop: '5px', marginBottom: '5px' }} />
              </Grid> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor="black" color="white" p={2}>
        <Container>
          <Typography variant="body2" align="center" fontWeight="bold">
            Todos los derechos reservados © 2024 PM Custom.
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default TerminoPag;
