import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, {useState} from "react";
import Home from './components/PaginaWeb/Pagina de inicio/Home';
import QuienesSomos from './components/PaginaWeb/Pagina de inicio/Quienes somos';
import Page404 from './components/PaginaWeb/PageError';
import Servicios from './components/PaginaWeb/Pagina de inicio/Servicios';
import Contacto from './components/PaginaWeb/Pagina de inicio/Contacto';
import Proyectos from './components/PaginaWeb/Pagina de inicio/Proyectos';


function App() {
  
  // const [datos, setDatos] = useState(null);

  // const handleData = (data, callback) => {
  //   // console.log(data, "desde app");
  //   callback(data);
  //   setDatos(data);
  // };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Quienes-Somos" element={<QuienesSomos />} />
        <Route path="/Servicios" element={<Servicios />} />
        <Route path="/Servicios" element={<Servicios />} />
        <Route path="/Contacto" element={<Contacto />} />
        <Route path="/Proyectos" element={<Proyectos />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
