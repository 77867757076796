import React, { useEffect, useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
    Toolbar,
    Grid,
    Box,
    Card,
    IconButton,
    Typography,
    Button,
    Container,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
    Paper,
    TextField,
    Alert,
    Snackbar
} from "@mui/material";

import fondo from "./../Imagenes/Original-SUGAL-GROUP-01.jpg";

import LogoPlacaPmCustom from "./../Imagenes/Logo_Placa_PmCustom.jpg"
import LogoPlacaSugal from "./../Imagenes/Logo_Placa_Sugal.jpg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useNavigate } from "react-router-dom";

import fondoo from "./../Imagenes/Fondoo.jpg";
import contacto from "./../Imagenes/Contacto.jpg";

import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { fontWeight } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [giroEmpresa, setGiroEmpresa] = useState('');
    const [rutEmpresa, setRutEmpresa] = useState('');
    const [nombreContacto, setNombreContacto] = useState('');
    const [telefonoContacto, setTelefonoContacto] = useState('');
    const [comentarios, setComentarios] = useState('');

    const containerStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${contacto}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        minHeight: '20vh',
    };


    const settings = {
        dots: true,
        infinite: true,
        speed: 250,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const getInfoByItem = (item) => {
        switch (item) {
            case 1:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 2:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 3:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 4:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 5:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            case 6:
                return { title: "Sugal-group", passengers: "Empresa dedicada a la agricultura", image: fondo };
            default:
                return {};
        }
    };

    const mostrarAlertaFaltaInformacion = () => {
        setMostrarAlerta(true);
    };

    const mostrarAlertaGuardadoExito = () => {
        setMostrarAlertaExito(true);
    };

    const handleEnviar = async () => {
        if (
            nombreEmpresa &&
            giroEmpresa &&
            rutEmpresa &&
            nombreContacto &&
            telefonoContacto &&
            comentarios
        ) {
            // Enviar datos
            mostrarAlertaGuardadoExito();
        }
        else {
            mostrarAlertaFaltaInformacion();
        }
    }

    return (
        <>
            <NavbarL />
            <FloatingWhatsAppButton />
            
            <Box style={containerStyle}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
                >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="left" alignItems="center">
                            <Grid item justifyContent="left" alignItems="left" container sx={6} md={4}>
                                <Typography variant="h3" align="left" style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.07em" }} gutterBottom>
                                    PMCUSTOM
                                </Typography>
                                <br />
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                        color: "white"
                                    }}
                                >
                                    PmCustom es una empresa dedicada a la tecnológia, a través de la cual se busca entregar soluciones a medida para cada uno de nuestros clientes.
                                </Typography>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                </Box>
                <br />
                <br />
                <br />
                
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    ¿QUÉ HACEMOS?
                    </Typography>
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    textAlign: 'justify',
                                    height: '80%'
                                }}
                            >
                                PMCustom es una empresa basada en Valparaíso, Chile, conformada por un equipo técnico multidisciplinario 
                                con amplia experiencia en proyectos de innovación tecnológica buscando siempre estar a la vanguardia. 
                                Tenemos un amplio conocimiento del mercado para entregar soluciones ajustadas a tus requerimientos.
                            </Typography>

                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                        <br/>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    textAlign: 'justify',
                                    height: '80%'
                                }}
                            >
                                ¡Contáctanos para hacer realidad tus ideas tecnológicas y subir al siguiente nivel en tu empresa!
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6}>
                    <Card sx={{ width: '100%', borderRadius: '20px' }}>
                        <CardMedia component="img" sx={{ height: '100%', maxHeight: 500 }} image={LogoPlacaPmCustom} />
                    </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardMedia sx={{ height: '100%', maxHeight: 500 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#8900AC', textAlign: 'left' }} gutterBottom>
                            SISTEMAS EMBEBIDOS INTELIGENTES
                        </Typography>
                        <Typography sx={{ fontSize: '15px', textAlign: 'justify' }}>
                            En PMCUSTOM, somos especialistas en el desarrollo de sistemas embebidos con alto 
                            contenido tecnológico para distintas industrias el cual son el corazón de una 
                            amplia gama de tecnologías industriales inteligentes.  Nuestros sistemas están 
                            diseñados para ser robustos y confiables, capaces de operar en los entornos 
                            industriales más exigentes.
                        </Typography>
                        <br/>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#8900AC', textAlign: 'left' }} gutterBottom>
                            CONECTIVIDAD AVANZADA
                        </Typography>
                        <Typography sx={{ fontSize: '15px', textAlign: 'justify' }}>
                            Nuestros dispositivos tecnológicos integrados no solo funcionan de manera automatizada 
                            en campo, sino que también se complementan con sofisticadas interfaces web las cuales 
                            entregan información procesada para una toma de decisiones informadas.  Esto permite 
                            a los usuarios monitorear y controlar de manera remota sus operaciones, garantizando 
                            una gestión eficiente y en tiempo real.
                        </Typography>
                        </CardMedia>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <CardMedia sx={{ height: '100%', maxHeight: 500 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#8900AC', textAlign: 'left' }} gutterBottom>
                            NUEVOS MODELOS DE NEGOCIOS PARA TU EMPRESA
                        </Typography>
                        <Typography sx={{ fontSize: '15px', textAlign: 'justify' }}>
                            Innova en tu negocio incorporando nuevos canales de ventas diseñando con nosotros tus propios 
                            paquetes tecnológicos y productos customizados, los cuales te permitirán diversificar tus 
                            modelos de negocios bajo la creación de empresas spinoff.
                        </Typography>
                        <br/>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#8900AC', textAlign: 'left' }} gutterBottom>
                            FINANCIAMIENTO PARA TUS PROYECTOS
                        </Typography>
                        <Typography sx={{ fontSize: '15px', textAlign: 'justify' }}>
                            PMCustom tiene una vasta experiencia en mecanismos de financiamientos CORFO desde la ley I+D 
                            hasta fondos concursables, para todos los niveles de desarrollo en los que se encuentre tu 
                            proyecto, prototipado, escalamiento y empaquetamiento.  Entregamos asesorías para determinar 
                            el grado de admisibilidad de tus proyectos tecnológicos a estos mecanismos de financiamiento
                        </Typography>
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Card sx={{ width: '100%', borderRadius: '20px' }}>
                        <CardMedia component="img" sx={{ height: '100%', maxHeight: 500 }} image={LogoPlacaSugal} />
                    </Card>
                    </Grid>
                </Grid>
                <br />
                <br />
                {/* <Typography variant="h4" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                    Nuestros Clientes
                </Typography>
                <Slider {...settings}>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                        <Grid>
                            <Grid key={item} item justifyContent="center" alignItems="center" style={{ padding: "10px", margin: "auto", textAlign: "center" }}>
                                <CardActionArea>
                                    <CardMedia component="img" sx={{ maxWidth: "150px", height: "50px", margin: "auto" }} image={getInfoByItem(item).image} alt="Vehiculos" />
                                    <Typography variant="h6" component="div" textAlign="center">
                                        {getInfoByItem(item).title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="center">
                                        {getInfoByItem(item).passengers}
                                    </Typography>
                                    <CardContent>
                                    </CardContent>
                                </CardActionArea>
                            </Grid>
                        </Grid>
                    ))}
                </Slider> */}
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                        NUESTRO ENFOQUE
                    </Typography>
                </Container>
                <br />
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '400px',
                                    height: isMobile ? '100%' : '200px',
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                    FIABILIDAD
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    La fiabilidad es una de las principales áreas de interés en el diseño de productos de PmCustom.
                                    Contamos con una larga experiencia en la fabricación de dispositivos industriales capaces de soportar los
                                    entornos más rigurosos y de ofrecer continuidad en la conexión con múltiples escenarios de respaldo.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '400px',
                                    height: isMobile ? '100%' : '200px',
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="left" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                    SEGURIDAD
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Garantizamos el máximo nivel de seguridad para todos los productos de PmCustom realizando
                                    pruebas de seguridad periódicas y publicando actualizaciones de firmware periódicas para eliminar
                                    cualquier riesgo de infracción.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={4}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '400px',
                                    height: isMobile ? '100%' : '200px',
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                    FACILIDAD DE USO
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Los dispositivos industriales de PmCustom están diseñados principalmente para aplicaciones
                                    profesionales, pero siguen siendo fáciles de usar. El uso de nuestros productos no requiere ninguna
                                    formación especial y puede ahorrar una cantidad considerable de tiempo durante la fase de implementación.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                </Container>
            <TerminoPag />
            <Snackbar
                open={mostrarAlerta}
                autoHideDuration={3000}
                onClose={() => setMostrarAlerta(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    Por favor, complete todos los campos obligatorios.
                </Alert>
            </Snackbar>
            <Snackbar
                open={mostrarAlertaExito}
                autoHideDuration={3000}
                onClose={() => setMostrarAlertaExito(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    ¡Enviado con éxito!
                </Alert>
            </Snackbar>
        </>
    )
}

export default Home
