import React, { useEffect, useState,useRef} from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Toolbar,
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  TextField,
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";

import LogoPlacaSugal from "./../Imagenes/Logo_Placa_Sugal.jpg"
import Vision_Mision from "./../Imagenes/Vision-Mision.jpg"

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const QuienesSomos = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyle = {
      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '20vh',
    };

    const containerStylee = {
        background: `url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
      };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <>
            <NavbarL/>
            <FloatingWhatsAppButton/>
            <br />
                <br />
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                        Nuestra Visión
                    </Typography>
                
                <br />
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={6} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={3.5}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '400px',
                                    height:"100%",
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="left" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                    VISIÓN
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Queremos ser la organización que contribuya en crear, implementar y mantener proyectos a través de la innovación 
                                    tecnológica sustentable y con ello ayudar a conseguir que la industria sea cada vez más precisa, alcanzando 
                                    sus niveles de productividad óptimos y con ello asegurándonos que nuestro medio ambiente reciba el menor impacto posible.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={3.5}>
                            <CardMedia component="img" height="100%" image={Vision_Mision} />
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={3.5}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '400px',
                                    height:"100%",
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="left" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                    MISIÓN
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Somos una organización horizontal con capacidad de ponernos en el lugar de nuestros clientes, integrándonos a sus 
                                    operaciones y problemáticas de la industria para la creación de soluciones con altos grados tecnológicos que 
                                    estén acorde con la sustentabilidad y el respeto por el medio ambiente.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={6} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={6}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    bgcolor: '#e8eaf6',
                                    width: '100%',
                                    height:"100%",
                                    p: 3,
                                }}
                            >
                                <Typography variant="h5" align="center" style={{ color: "black", fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                PROPUESTA DE VALOR
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        textAlign: 'center',
                                    }}
                                >
                                    El entendimiento de las problemáticas de nuestros clientes y el entorno donde se desarrollan, son fundamentales 
                                    para conseguir resultados adaptados a la realidad de la industria.  Es por ello que PMCustom utiliza metodologías 
                                    flexibles con un amplio conocimiento del mercado tecnológico, entregando así un acompañamiento durante todo el 
                                    ciclo del proyecto desde la etapa del levantamiento del requerimiento, diseño de soluciones, factibilidad técnica,
                                    prototipado y producto tecnológico.  Para nosotros el poder contribuir a la industria desde estos 3 pilares 
                                    del conocimiento y acción es nuestro principal objetivo:
                                </Typography>
                                <br/>
                                <Typography
                                    sx={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Medio ambiente,
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Escalabilidad
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '17px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Continuidad operacional.
                                </Typography>
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                    <Typography variant="h3" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                        Equipo 
                    </Typography>
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="center" alignItems="center" spacing={2} >
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={3}>
                                <Card sx={{ maxWidth: "100%", borderRadius: "20px"}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    width="275px"
                                    // image="/static/images/cards/contemplative-reptile.jpg"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Francisco Cubas
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    CEO
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    PMCustom
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Asesorías en I+D+i
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <LinkedInIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <EmailIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <PhoneIphoneIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </CardActions>
                                </Card>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={3}>
                                <Card sx={{ maxWidth: "100%", borderRadius: "20px"}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    width="275px"
                                    // image="/static/images/cards/contemplative-reptile.jpg"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Maximiliano Ramirez
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Departamento I+D+i
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    PMCustom
                                    </Typography>
                                    <br/>
                                </CardContent>
                                <CardActions>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <LinkedInIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <EmailIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <PhoneIphoneIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </CardActions>
                                </Card>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={3}>
                                <Card sx={{ maxWidth: "100%", borderRadius: "20px"}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    width="275px"
                                    // image="/static/images/cards/contemplative-reptile.jpg"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Jorge Nuñez
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Departamento I+D+i
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    PMCustom
                                    </Typography>
                                    <br/>
                                </CardContent>
                                <CardActions>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <LinkedInIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <EmailIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <PhoneIphoneIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </CardActions>
                                </Card>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={3}>
                            <Card sx={{ maxWidth: "100%", borderRadius: "20px"}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    width="275px"
                                    // image="/static/images/cards/contemplative-reptile.jpg"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Michael Yañez
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Departamento Ti
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    PMCustom
                                    </Typography>
                                    <br/>
                                </CardContent>
                                <CardActions>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <LinkedInIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <EmailIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                                        <PhoneIphoneIcon 
                                            fontSize="large"
                                            sx={{
                                            bgcolor: "#8900AC",
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 30,
                                            borderRadius: "100%", 
                                            border: "3px solid #8900AC",
                                            marginRight: '5px',
                                            }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </CardActions>
                                </Card>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                <br />
                <br />
                <br />
                </Container>
            <TerminoPag/>
        </>
    )
}

export default QuienesSomos
