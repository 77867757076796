import React, { useEffect, useState,useRef} from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Toolbar,
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  TextField,
  Snackbar,
  Alert
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";
import contacto from "./../Imagenes/Contacto.jpg";


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const Contacto = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyle = {
      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '20vh',
    };

    const containerStylee = {
        background: `url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
      };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [giroEmpresa, setGiroEmpresa] = useState('');
    const [rutEmpresa, setRutEmpresa] = useState('');
    const [nombreContacto, setNombreContacto] = useState('');
    const [telefonoContacto, setTelefonoContacto] = useState('');
    const [comentarios, setComentarios] = useState('');

    const mostrarAlertaFaltaInformacion = () => {
        setMostrarAlerta(true);
    };

    const mostrarAlertaGuardadoExito = () => {
        setMostrarAlertaExito(true);
    };


    const handleEnviar = async () => {
        if (
            nombreEmpresa &&
            giroEmpresa &&
            rutEmpresa &&
            nombreContacto &&
            telefonoContacto &&
            comentarios
        ) {
            // Enviar datos
            mostrarAlertaGuardadoExito();
        }
        else {
            mostrarAlertaFaltaInformacion();
        }
    }

    return(
        <>
            <NavbarL/>
            <FloatingWhatsAppButton/>
            <br />
                <br />
                <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={3}>
                            <Typography
                                color="#8900AC"
                                fontWeight="bold"
                                variant={isMobile ? 'h4' : 'h3'}
                            >
                                CONTACTANOS
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={12}>
                            <Card
                                sx={{
                                    width: "100%",
                                    height: 585,
                                    borderRadius: '20px',
                                }}>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={4.5}>
                                            <Card sx={{
                                                width: "100%",
                                                height: "100%",
                                                p: 2.5,
                                            }}>
                                                <Typography variant="h5" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#8900AC" }} gutterBottom>
                                                    INGRESA TUS DATOS
                                                </Typography>
                                                <CardContent>
                                                    <Grid container xs={12} md={12} >
                                                        <Grid item container justifyContent="center" alignItems="center" spacing={2} >
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Nombre Empresa"
                                                                    placeholder="PmCustom"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={nombreEmpresa}
                                                                    onChange={(e) => setNombreEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Giro Empresa"
                                                                    placeholder="Tecnologia"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={giroEmpresa}
                                                                    onChange={(e) => setGiroEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Rut Empresa"
                                                                    placeholder="76.123.456-7"
                                                                    type="float"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={rutEmpresa}
                                                                    onChange={(e) => setRutEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Nombre del contacto"
                                                                    placeholder="Nombre Apellido"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={nombreContacto}
                                                                    onChange={(e) => setNombreContacto(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Teléfono del contacto"
                                                                    placeholder="+569 12345678"
                                                                    type="number"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={telefonoContacto}
                                                                    onChange={(e) => setTelefonoContacto(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Comentarios"
                                                                    placeholder="Necesitamos información sobre..."
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={comentarios}
                                                                    onChange={(e) => setComentarios(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <Button
                                                                    size="medium"
                                                                    color="primary"
                                                                    sx={{
                                                                        width: '100%',
                                                                        bgcolor: '#8900AC',
                                                                        color: 'white',
                                                                        padding: '10px',
                                                                        borderRadius: '20px',
                                                                    }}
                                                                    onClick={handleEnviar}
                                                                >
                                                                    Enviar
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={7.5} md={7.5}>
                                            <CardMedia component="img" height="610px" image={contacto} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                </Container>
                <br />
                <br />
                <br />
            <TerminoPag/>
            <Snackbar
                open={mostrarAlerta}
                autoHideDuration={3000}
                onClose={() => setMostrarAlerta(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    Por favor, complete todos los campos obligatorios.
                </Alert>
            </Snackbar>
            <Snackbar
                open={mostrarAlertaExito}
                autoHideDuration={3000}
                onClose={() => setMostrarAlertaExito(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    ¡Enviado con éxito!
                </Alert>
            </Snackbar>
        </>
    )
}

export default Contacto
