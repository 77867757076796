import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Button,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../Imagenes/pmcustom_preview_lav-2.png";

const NavbarL = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { text: 'Inicio', link: '/' },
    { text: 'Quienes Somos', link: '/Quienes-Somos' },
    { text: 'Servicios', link: '/Servicios' },
    { text: 'Contacto', link: '/Contacto' },
  ];

  return (
    <AppBar position="static" color="default" sx={{ marginBottom: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="h6">
                <img 
                  src={Logo} 
                  alt="Logo" 
                  style={{ width: '80%', height: '80%', marginTop: '10px', marginBottom: '5px' }}
                />
              </Typography>
            </Grid>
            {isSmallScreen ? (
              <>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle} sx={{ marginLeft: 'auto' }}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={isMenuOpen} onClose={handleMenuToggle}>
                  <List>
                    {menuItems.map((item, index) => (
                      <ListItem button key={index} component={Link} to={item.link} onClick={handleMenuToggle}>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    ))}
                    <ListItem button component={Link} to="/Proyectos" onClick={handleMenuToggle}>
                      <Button
                        sx={{
                          bgcolor: '#8900AC',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '5px',
                          width: '100%'
                        }}
                      >
                        Proyectos
                      </Button>
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              <Grid item sm={8} container justifyContent="flex-end" spacing={0}>
                {menuItems.map((item, index) => (
                  <Button key={index} color="inherit" component={Link} to={item.link} sx={{ fontWeight: 'bold' }}>
                    {item.text}
                  </Button>
                ))}
                <Button
                  color="inherit"
                  component={Link}
                  to="/Proyectos"
                  sx={{
                    bgcolor: '#8900AC',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">
                    Proyectos
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavbarL;
